var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Работы","create":"","create-title":"Создать работу","url-create":"/job/create","url-update":"/job/update/:id","store-module":"job","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Название', value: 'name', width: 1, sortable: false },
        { text: 'Тип', value: 'type', width: 1 },
        { text: 'Предмет', value: 'subject', width: 1 },
        { text: 'Класс', value: 'grade', width: 1 },
        { text: 'Вариант', value: 'option', width: 1 },
        { text: 'Тип проверки', value: 'check_type', width: 1 },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems,"data-table-options":{
        'show-select': true,
    }},scopedSlots:_vm._u([{key:`title.right`,fn:function(){return [_c('v-btn',{staticClass:"ml-auto mr-2 white--text",attrs:{"to":"/job/answers-pdf","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-file-pdf-box ")]),_c('span',[_vm._v("Ответы в pdf")])],1),_c('v-btn',{staticClass:"ml-auto mr-2 white--text",attrs:{"to":"/job/print","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-printer ")]),_c('span',[_vm._v("Печать вариантов")])],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }