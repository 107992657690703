<template>
    <grid-view 
        title="Работы"
        create
        create-title="Создать работу"
        url-create="/job/create"
        url-update="/job/update/:id"
        store-module="job"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Название', value: 'name', width: 1, sortable: false },
            { text: 'Тип', value: 'type', width: 1 },
            { text: 'Предмет', value: 'subject', width: 1 },
            { text: 'Класс', value: 'grade', width: 1 },
            { text: 'Вариант', value: 'option', width: 1 },
            { text: 'Тип проверки', value: 'check_type', width: 1 },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
        :data-table-options="{
            'show-select': true,
        }"
    >
        <!-- Extra buttons in title -->
        <template #[`title.right`]>

            <v-btn to="/job/answers-pdf" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-file-pdf-box
                </v-icon>
                <span>Ответы в pdf</span>
            </v-btn>

            <v-btn to="/job/print" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-printer
                </v-icon>
                <span>Печать вариантов</span>
            </v-btn>

        </template>
        <!-- Filter -->
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'JobIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Role
                const role_i = _.findIndex(this.$store.state.job.roles, {value: item.role});
                _.set(item, 'role', _.get(this, `$store.state.job.roles.${role_i}.text`, 'Неизвестно'));

                return item;
            })
        } 
    }
}
</script>